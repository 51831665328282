import { Controller } from "stimulus"

// Connects to data-controller="tooltip-pool"
export default class extends Controller {
  static targets = ["content", "i", "invitationInfo", "invitationContent", "linkInfo", "linkContent"]

  show() {
    this.contentTarget.style.display = "block"
    this.hide()
  }

  hide() {
    this.iTarget.addEventListener("mouseleave", () => {
      this.contentTarget.style.display = "none"
    })
  }

  hideInvitation() {
    this.invitationInfoTarget.addEventListener("mouseleave", () => {
      this.invitationContentTarget.style.display = "none"
    })
  }

  showInvitation() {
    this.invitationContentTarget.style.display = "block"
    this.hideInvitation()
  }

  hideLink() {
    this.linkInfoTarget.addEventListener("mouseleave", () => {
      this.linkContentTarget.style.display = "none"
    })
  }

  showLink() {
    this.linkContentTarget.style.display = "block"
    this.hideLink()
  }
}
