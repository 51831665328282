import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "modalParticipation", "modalDetail", "modalContact", "modalInvitation"]

  connect() {
    // Fermer la modal en appuyant sur la touche "Escape"
    this.handleEscapeBound = this.handleEscape.bind(this);
    document.addEventListener("keydown", this.handleEscapeBound);
  }

  openParticipation(event) {
    event.preventDefault();
    this.modalInvitationTarget.style.display = "flex";
    this.modalTarget.style.display = "none";
  }

  open(event) {
    event.preventDefault();
    this.modalTarget.style.display = "flex";
    document.body.style.overflow = "hidden"; // Empêche le défilement de la page en arrière
    this.modalParticipationTarget.style.display = "none";
  }

  openContact(event) {
    event.preventDefault();
    this.modalContactTarget.style.display = "flex";
    this.modalTarget.style.display = "none";
  }

  cancelContact(event) {
    event.preventDefault();
    this.modalContactTarget.style.display = "none";
  }

  closeDetail(event) {
    event.preventDefault();
    this.modalDetailTarget.style.display = "none";
    this.modalTarget.style.display = "flex";
  }

  cancel(event) {
    event.preventDefault();
    this.modalDetailTarget.style.display = "none";
  }

  openDetail(event) {
    event.preventDefault();
    this.modalTarget.style.display = "none";
    this.modalDetailTarget.style.display = "flex";
  }

  closeContact(event) {
    event.preventDefault();
    this.modalTarget.style.display = "flex";
    this.modalContactTarget.style.display = "none";
  }

  close() {
    this.modalTarget.style.display = "none";
    document.body.style.overflow = "auto"; // Rétablit le défilement de la page
  }

  outsideClickDetail(event) {
    if (event.target === this.modalDetailTarget) {
      this.closeDetail()
    }
  }

  outsideClick(event) {
    if (event.target === this.modalTarget) {
      this.close()
    }
  }

  outsideClickContact(event) {
    if (event.target === this.modalContactTarget) {
      this.cancelContact(event)
    }
  }

  handleEscape(event) {
    if (event.key === "Escape") {
      this.close();
    };
  }
}
