import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menuOpen", "menuClose", "burgerMenu",
  "menuOpenPro", "menuClosePro", "burgerMenuPro", "menuOverlay", "menuOverlayPro" ]

  toggle() {
    this.menuOpenTarget.classList.toggle("hidden");
    this.menuCloseTarget.classList.toggle("hidden");
    this.burgerMenuTarget.classList.toggle("hidden");
    this.menuOverlayTarget.classList.toggle("hidden");
  }

  togglePro() {
    this.menuOpenProTarget.classList.toggle("hidden");
    this.menuCloseProTarget.classList.toggle("hidden");
    this.burgerMenuProTarget.classList.toggle("hidden");
    this.menuOverlayProTarget.classList.toggle("hidden");
  }
}
