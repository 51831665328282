import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["offer", "btnOffer", "btnCloseOffer", "oneChat"]


  displayOffer(event) {
    event.preventDefault();
    this.offerTarget.classList.add("display-block");
    this.btnOfferTarget.style.display = "none";
    this.btnCloseOfferTarget.style.display = "block";
    this.oneChatTarget.style.display = "none";
  }

  closeOffer(event) {
    event.preventDefault();
    this.offerTarget.classList.remove("display-block");
    this.btnOfferTarget.style.display = "block";
    this.btnCloseOfferTarget.style.display = "none";
    this.oneChatTarget.style.display = "block";
  }
}
