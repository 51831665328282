import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "modal", "dates", "btn"]
  static values = {
    offerId: Number,
    onDemandOfferId: Number,
    serviceId: Number
  }

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("open_modal")) {
      this.displayDraftBooking();
    }
  }

  displayDraftBooking() {
    this.formTarget.classList.remove("offer-display-none");
    this.btnTarget.classList.add("offer-display-none");
    fetch(`/bookings?offer_id=${this.offerIdValue}&service_id=${this.serviceIdValue}&on_demand_offer_id=${this.onDemandOfferIdValue}&draft_booking=true`, {
      method: 'POST',
      body: {},
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Accept': 'application/json'
      }
    })
  }

  submit(event) {
    event.preventDefault(); // Pour éviter la soumission classique du formulaire
    let formData = new FormData(event.target);

    fetch(`${event.target.action}?offer_id=${this.offerIdValue}`, {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Accept': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'ok') {
        this.displayThankYouModal();
      }
    });
  }

  displayThankYouModal() {
    this.modalTarget.style.display = 'block';
    this.datesTarget.style.display = 'none';
  }
}
