import { Controller } from "stimulus";
import $ from "jquery";
import { get } from "@rails/request.js"
import "select2";
import 'select2/dist/css/select2.min.css';

export default class extends Controller {

  connect() {
    $(this.element).select2({
      placeholder: "Toutes les catégories",
      allowClear: true,
    });
     $(this.element).on('select2:select', this.submitForm.bind(this));
  }

  submitForm(event) {
    this.element.form.requestSubmit()
  }
}
