import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "babies"]

  connect() {
    // Active le premier onglet
    this.tabTargets[0].classList.add("active")
    this.babiesTargets[0].classList.add("active")
  }

  toggle(event) {
    event.preventDefault()
    const selectedTab = event.currentTarget

    // Désactiver tous les onglets et contenus
    this.tabTargets.forEach(tab => tab.classList.remove("active"))
    this.babiesTargets.forEach(pane => pane.classList.remove("active"))

    // Activer l'onglet cliqué et le contenu correspondant
    selectedTab.classList.add("active")
    const targetBaby = this.babiesTargets.find(baby => baby.id === selectedTab.dataset.tab)
    if (targetBaby) {
      targetBaby.classList.add("active")
    }
  }

  hasActiveTab() {
    return this.tabTargets.some(tab => tab.classList.contains("active"))
  }
}
