import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    // Fermer la modal en appuyant sur la touche "Escape"
    document.addEventListener("keydown", this.handleEscape.bind(this))
  }

  open(event) {
    event.preventDefault()
    this.modalTarget.style.display = "flex"
    document.body.style.overflow = "hidden" // Empêche le défilement de la page en arrière
  }

  close() {
    this.modalTarget.style.display = "none"
    document.body.style.overflow = "auto" // Rétablit le défilement de la page
  }

  handleEscape(event) {
    if (event.key === "Escape") {
      this.close()
    }
  }

  // Fermer la modal si l'utilisateur clique en dehors du contenu de la modal
  outsideClick(event) {
    if (event.target === this.modalTarget) {
      this.close()
    }
  }
}
