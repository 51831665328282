import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["offer", "chat", "btnOffer", "btnCloseOffer", "oneChat"]

  displayOffer(event) {
    event.preventDefault();
    this.offerTarget.classList.add("display-block");
    this.chatTarget.style.display = "none";
    this.btnOfferTarget.style.display = "none";
    this.btnCloseOfferTarget.style.display = "block";
    if (window.innerWidth <= 500) {
      console.log(this.oneChatTarget)
      this.oneChatTarget.style.display = "none";
    }
  }

  closeOffer(event) {
    event.preventDefault();
    this.offerTarget.classList.remove("display-block");
    this.chatTarget.style.display = "block";
    this.btnOfferTarget.style.display = "block";
    this.btnCloseOfferTarget.style.display = "none";

    if (window.innerWidth <= 500) {
      this.oneChatTarget.style.display = "block";
    }
  }
}
