import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', this.submitForm.bind(this))
  }

  submitForm(event) {
    this.element.form.requestSubmit()
  }
}
