import { Controller } from "stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {

  static targets = ["chatList", "messagesPanel"]

  highlightActiveChatroom(event) {
    if (window.innerWidth <= 500) {
      event.preventDefault()
      const url = event.currentTarget.href
      fetch(url, {
        headers: {
          "Turbo-Frame": "messages_frame"
        }
      })
      .then(response => response.text())
      .then(html => {
        this.messagesPanelTarget.innerHTML = html
        this.chatListTarget.classList.add('hidden')
        this.messagesPanelTarget.classList.add('visible')
      })
      .catch(error => console.error('Erreur:', error))
    } else {
      document.querySelectorAll('.chatroom-item').forEach(item => {
        item.classList.remove('selected')
      })

      const activeChatroomItem = event.currentTarget.querySelector(".chatroom-item");
      activeChatroomItem.classList.add('selected');
    }
  }

  backToChatList() {
    if (window.innerWidth <= 500) {
      this.messagesPanelTarget.classList.remove('visible')
      this.chatListTarget.classList.remove('hidden')
      this.messagesPanelTarget.innerHTML = '<p>Pas encore de chatrooms…</p>'
    }
  }
}
