import { Controller } from "stimulus"

export default class extends Controller {
  toggle(event) {
    const button = this.element.querySelector('.toggle-button')
    const hiddenInput = this.element.querySelector('#active_only')

    // Toggle the 'active' class
    const isActive = button.classList.toggle('active')

    // Update the hidden input value
    hiddenInput.value = isActive ? 'true' : 'false'

    // Soumettre le formulaire automatiquement
    this.element.closest('form').requestSubmit()
  }
}
