export default class Bridge {
  static register(token) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }

    fetch("/notification_tokens", {
      headers: headers,
      method: "POST",
      body: JSON.stringify({ token: token })
    })
  }
}
