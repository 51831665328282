import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"];

  copy(event) {
    navigator.clipboard.writeText(this.inputTarget.value);
    event.currentTarget.disabled = true;
  }
}
