import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fileInput", "filename", "submit"]

  triggerFileInput() {
    this.fileInputTarget.click()
  }

  changeFile() {
    const file = this.fileInputTarget.files[0]
    if (file) {
      this.filenameTarget.value = file.name
      this.submitTarget.disabled = false
    } else {
      this.filenameTarget.value = ""
      this.submitTarget.disabled = true
    }
  }
}

